<div class="conversation-container" #conversationContainer>
    <div class="conversation" #conversationBody (scroll)="onConversationScroll()">
        <div class="conversation-header">
            <div class="conversation-header-title">
                <h3>{{ title }}</h3>
            </div>
        </div>
        <div class="conversation-body" id="conversation-body">
            <div class="conversation-messages">
                <message *ngFor="let message of messageHistory" [message]="message"></message>
            </div>
        </div>
    </div>

    <div class="conversation-input">
        <!-- Scroll to latest button -->
        <div *ngIf="showScrollToLatest" tabindex="0" class="scroll-to-latest" (click)="scrollToBottom()">
            <span>
                &#8595; <!-- Down arrow -->
            </span>
        </div>

        <div class="grow-wrap" #growWrapper>
            <textarea
                class="text-box"
                [(ngModel)]="outgoingMessageContent"
                (keydown.enter)="$event.preventDefault(); onSend();"
                (keyup.shift.arrowdown)="navigateRequestHistory('down')"
                (keyup.shift.arrowup)="navigateRequestHistory('up')"
                onInput="this.parentNode.dataset.replicatedValue = this.value;"
                placeholder="Type a message and press enter to send..."
            ></textarea>
        </div>
    </div>
</div>
