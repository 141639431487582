import { Injectable } from '@angular/core';
import { Confirmation, ConfirmationService as NgPrimeConfirmationService } from 'primeng/api';

@Injectable({
    providedIn: 'root',
})
export class ConfirmationService {
    private RELOAD_CONFIRMATION_KEY = 'reloadConfirmation';

    constructor(private confirmations: NgPrimeConfirmationService) {}

    showReloadConfirmation(newVersion: string, reloadCallback: (version: string) => void) {
        const confirmation: Confirmation = {
            message: `A new version of this application is available (${newVersion}). Do you want to reload?`,
            header: 'Update Available',
            key: this.RELOAD_CONFIRMATION_KEY,
            icon: 'pi pi-info-circle',
            rejectButtonStyleClass: 'p-button-outlined',
            accept: () => {
                reloadCallback(newVersion);
            },
            reject: () => {
                // Do nothing
            },
        };

        this.confirmations.confirm(confirmation);
    }
}
