import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

@Component({
    selector: 'bubble-chart',
    templateUrl: './bubble-chart.component.html',
    styleUrls: ['./bubble-chart.component.scss'],
})
export class BubbleChartComponent implements OnInit {
    @Input() bubbleChartData: any;
    @Input() chartRequestData: any;
    @Input() yAxisLabel: any;
    @Output() chartClickedEvent = new EventEmitter<any>();

    chartData: string[] = [];

    public bubbleChartOptions: ChartOptions = {};

    public bubbleChartType: ChartType = 'bubble';

    public bubbleChartLegend = false;

    public bubbleChartPlugins = [pluginDataLabels];

    ngOnInit() {
        const obj: any = [
            {
                data: this.bubbleChartData.data,
            },
        ];
        this.chartData = obj;

        this.bubbleChartOptions = {
            responsive: true,

            scales: {
                x: {
                    min: this.bubbleChartData.properties['X-Axis-Range'].min,

                    max: this.bubbleChartData.properties['X-Axis-Range'].max,
                    grid: {
                        color: 'rgba(0, 0, 0, 0)',
                        // zeroLineColor: 'rgba(242, 244, 247)'
                    },
                    // ticks: {

                    // },
                    title: {
                        display: true,
                        text: 'DOS START',
                        font: { size: 15 },
                        // fontStyle: "bold"
                    },
                },

                y: {
                    min: this.bubbleChartData.properties['Cohort-Y-Axis-Range'].min,
                    max: this.bubbleChartData.properties['Cohort-Y-Axis-Range'].max,
                    grid: {
                        color: 'rgba(0, 0, 0, 0)',
                        // zeroLineColor: 'rgba(242, 244, 247)'
                    },
                    ticks: {},
                    title: {
                        display: true,
                        text: this.yAxisLabel,
                        font: { size: 15 },
                        // fontStyle: "bold"
                    },
                },
            },
            plugins: {
                legend: {
                    position: 'bottom',
                },
                datalabels: {
                    anchor: 'center',
                    align: 'center',
                    font: {
                        size: 10,
                    },
                    formatter: function (value: any, context: any) {
                        return value.v;
                    },
                },
                tooltip: {
                    callbacks: {
                        title: items => {
                            const item = items[0];
                            const { chart } = item;
                            // const nextLabel = d.datasets[0].data[t.index].v || '';
                            return `test`;
                        },
                        //   label: function (t: any, d: any) {
                        //     return d.datasets[0].data[t.index].v
                        //   }
                    },
                },
            },
        };
    }

    chartClicked(e: any): void {
        if (e.active.length > 0) {
            this.chartClickedEvent.emit(e);
        }
    }
}
