import {Inject, Injectable} from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService, ToasterService } from '../_services';
import {DOCUMENT} from '@angular/common';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private _Router: Router,
        private _ToasterService: ToasterService,
        @Inject(DOCUMENT) private document: Document,
        private authenticationService: AuthenticationService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError(err => {
                switch (err.status) {
                    case 401:
                        // this._ToasterService.openErrorToaster('Session expired. Please login again!')
                        // auto logout if 401 response returned from api
                        this.authenticationService.logout();
                        this.document.location.href = '/auth/login';
                        break;
                    case 506:
                        this._ToasterService.openErrorToaster(
                            'Error',
                            'User has no permission to access this API!'
                        );
                        // auto logout if 506 response returned from api
                        this.authenticationService.logout();
                        break;
                }
                //const error = err.error.message || err.statusText;
                return throwError(err);
            })
        );
    }
}
