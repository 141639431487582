import { HttpHeaders } from '@angular/common/http';

import { environment } from '../environments/environment';

// api end point - dev env
export const baseUrl = environment.baseUrl;

export const adminDashboardBaseUrl = baseUrl + '/api/v1/admin';
export const adminBaseUrlV2 = baseUrl + '/api/v2/admin';

export const clientBaseUrl = baseUrl + '/api/v1/client';

export const clientBaseUrlV2 = baseUrl + '/api/v2/client';

export const userBaseUrl = baseUrl + '/api/v1/admin/user';

export const discussionBaseUrl = baseUrl + '/api/v1/admin/discussion';

export const metadataModelBaseUrl = baseUrl + '/api/metadata-models';

// frontend logging url
export const frontendLoggingEndpoint = baseUrl + '/api/v1/admin/frontend/log';
// frontend timings url
export const frontendTimingsEndpoint = baseUrl + '/api/v1/admin/frontend/timings';

// service monitoring endpoints
export const monitoringBaseUrl = baseUrl + '/api/v1/admin/monitoring';
export const serviceMonitoringEndpoint = monitoringBaseUrl + '/service-status';

// data pipeline error endpoints
export const dataPipelineErrorEndpoint = baseUrl + '/api/v1/datapipeline/errors';

export const fileInstanceCountEndpoint =
    adminDashboardBaseUrl + '/dashboard/data/file/instances-count';

export const metadataCountEndpoint =
    adminDashboardBaseUrl + '/dashboard/meta-data/file/instances-count';

export const summaryReportEndpoint = adminDashboardBaseUrl + '/dashboard/data/summary-report-count';

export const fileInstanceChartDataEndpoint =
    adminDashboardBaseUrl + '/dashboard/data/file/chart-data';

export const dashboardListEndpoint = adminDashboardBaseUrl + '/analytics/dashboard/list';

export const clientFileInstanceCountEndpoint =
    adminDashboardBaseUrl + '/client-summary/file-instances-count';

export const clientMetadataCountEndpoint =
    adminDashboardBaseUrl + '/client-summary/meta-data-instances-count';

export const clientSummaryReportEndpoint =
    adminDashboardBaseUrl + '/client-summary/summary-report-count';

export const clientFileInstanceChartDataEndpoint =
    adminDashboardBaseUrl + '/client-summary/file-chart-data';

export const clientHomeFileInstanceChartDataEndpoint =
    adminDashboardBaseUrl + '/client-summary/file-chart-data';

export const clientMetaDataChartDataEndpoint =
    adminDashboardBaseUrl + '/client-summary/meta-data-chart-data';

export const addClientEndpoint = adminDashboardBaseUrl + '/client/create';

export const activeClientChartDataEndpoint = adminDashboardBaseUrl + '/client/active/data-table';
export const activeNetworkDataEndpoint = adminDashboardBaseUrl + '/client/active/data-table';
export const activeNetworkClientListEndpoint = adminDashboardBaseUrl + '/client/active/list';
export const archivedClientChartDataEndpoint =
    adminDashboardBaseUrl + '/client/archived/data-table';
export const archivedNetworkDataEndpoint = adminDashboardBaseUrl + '/client/archived/data-table';
export const archivedNetworkClientDataEndpoint = adminDashboardBaseUrl + '/client/archived';

export const emailCheckEndpoint = adminDashboardBaseUrl + '/network/emailCheck';
export const clientEmailCheckEndpoint = adminDashboardBaseUrl + '/client/emailCheck';

export const clientByNetworkIdEndpoint = adminDashboardBaseUrl + '/client/network';

export const clientAuditServiceDataEndpoint = adminDashboardBaseUrl + '/audit/service/data-table';
export const clientAuditDataPipelineEndpoint = adminDashboardBaseUrl + '/audit/services';
export const auditDataPipelineServicesEndpoint = adminDashboardBaseUrl + '/audit/service-details';
export const clientAuditDataUploadOverviewEndpoint = adminDashboardBaseUrl + '/audit';

export const auditFileSectionsDataEndpoint =
    adminDashboardBaseUrl + '/audit/file/sections/data-table';

export const auditSectionFieldDataEndpoint =
    adminDashboardBaseUrl + '/audit/section/field/data-table';

export const clientAuditDataEndpoint = adminDashboardBaseUrl + '/audit/data/data-table';

export const categoryTableDataEndpoint = adminDashboardBaseUrl + '/mapping/category/data-table';

export const templateTableDataEndpoint = adminDashboardBaseUrl + '/mapping/template/data-table';

export const networkTableDataEndpoint = adminDashboardBaseUrl + '/network/data-table';

export const networkTableDataDeleteEndpoint = adminDashboardBaseUrl + '/network';

export const metaDataChartDataEndpoint =
    adminDashboardBaseUrl + '/dashboard/meta-data/file/chart-data';

export const saveTemplateEndpoint = adminDashboardBaseUrl + '/mapping/template';

export const validateTemplateEndpoint = adminDashboardBaseUrl + '/mapping/template/validate';

export const getTemplateEndpoint = adminDashboardBaseUrl + '/mapping/template/details';

export const allActiveSectionListEndpoint = adminDashboardBaseUrl + '/active/section/list';
export const getCalcVersionEndpoint =
    adminDashboardBaseUrl + '/calculation/meta-data/generateNewVersion';
export const getFlagVersionEndpoint = adminDashboardBaseUrl + '/meta-data/flag/generateNewVersion';

export const saveFlagEndpoint = adminDashboardBaseUrl + '/meta-data/flag/create';

export const getFlagListEndpoint = adminDashboardBaseUrl + '/meta-data/flag/data-table';

export const deleteFlagEndpoint = adminDashboardBaseUrl + '/meta-data/flag';

export const flagBaseEndpoint = adminDashboardBaseUrl + '/meta-data/flag';

export const flagFilterEndpoint = clientBaseUrl;

export const updateFlagEndpoint = adminDashboardBaseUrl + '/meta-data/flag/update';

export const getFlagEndpoint = adminDashboardBaseUrl + '/listOfFlagsByFieldIdAndFileId';

export const getCalculationListEndpoint =
    adminDashboardBaseUrl + '/calculation/meta-data/data-table';

export const documentElementsTableViewList = adminDashboardBaseUrl + '/document/element/data';

export const thumbNailImgPathGet = clientBaseUrl + '/doc-instance/get-file';

export const labelGroupList = adminDashboardBaseUrl + '/label/group/list';

export const getPredictionListEndpoint = adminDashboardBaseUrl + '/meta-data/prediction/data-table';

export const createCalculationEndpoint = adminDashboardBaseUrl + '/calculation/meta-data/create';

export const updateCalculationEndpoint = adminDashboardBaseUrl + '/calculation/meta-data/update';

export const calculationEndpoint = adminDashboardBaseUrl + '/calculation/meta-data';

export const searchFlagEndpoint = adminDashboardBaseUrl + '/meta-data/flag/search';

export const clusterListEndpoint = adminDashboardBaseUrl + '/meta-data/cluster/list';

export const searchLookupEndpoint = adminDashboardBaseUrl + '/lookup-file/search';

export const createPredictionEndpoint = adminDashboardBaseUrl + '/meta-data/prediction/create';

export const getPredictionEndpoint = adminDashboardBaseUrl + '/meta-data/prediction';

export const getPredictionPreviewEndpoint =
    adminDashboardBaseUrl + '/client/prediction/{clientId}/preview/{predictionId}';

export const getMissingPredictionEndpoint =
    adminDashboardBaseUrl +
    '/client/prediction/{clientId}/missing-prediction/{predictionId}/data-table';

export const updatePredictionEndpoint = adminDashboardBaseUrl + '/meta-data/prediction/update';

export const deletePredictionEndpoint = adminDashboardBaseUrl + '/meta-data/prediction';

export const getStatisticPreviewEndpoint = adminDashboardBaseUrl + '/statistic-preview';

export const getActiveClients = getStatisticPreviewEndpoint + '/clients';

export const getStatisticListEndpoint = adminDashboardBaseUrl + '/meta-data/statistics/data-table';

export const createStatisticEndpoint = adminDashboardBaseUrl + '/meta-data/statistics/create';

export const updateStatisticEndpoint = adminDashboardBaseUrl + '/meta-data/statistics/update';

export const statisticEndpoint = adminDashboardBaseUrl + '/meta-data/statistics';

export const lookupFileEndpoint = adminDashboardBaseUrl + '/lookup-file/search?searchText=';

export const searchFileEndpoint = adminDashboardBaseUrl + '/files/search?searchText=';

export const bucketFileEndpoint = adminDashboardBaseUrl + '/staging/bucket/field/list';

export const getClusterListEndpoint = adminDashboardBaseUrl + '/meta-data/cluster/data-table';

export const createClusterEndpoint = adminDashboardBaseUrl + '/meta-data/cluster/create';

export const getClusterEndpoint = adminDashboardBaseUrl + '/meta-data/cluster';

export const updateClusterEndpoint = adminDashboardBaseUrl + '/meta-data/cluster/update';

export const deleteClusterEndpoint = adminDashboardBaseUrl + '/meta-data/cluster';

export const getBenchmarkListEndpoint = adminDashboardBaseUrl + '/meta-data/benchmark/data-table';

export const createBenchmarkEndpoint = adminDashboardBaseUrl + '/meta-data/benchmark/create';

export const updateBenchmarkEndpoint = adminDashboardBaseUrl + '/meta-data/benchmark/update';

export const benchmarkEndpoint = adminDashboardBaseUrl + '/meta-data/benchmark';

export const searchStatisticEndpoint = adminDashboardBaseUrl + '/meta-data/statistics/search';

export const searchFieldEndpoint = adminDashboardBaseUrl + '/define-field/search';

export const getProjectEndpoint = adminDashboardBaseUrl + '/project/getProjectForClient';

export const getFilesForProjectEndpoint = adminDashboardBaseUrl + '/project/getFilesForProject';

export const scoringEndpoint = adminDashboardBaseUrl + '/score';

export const labelEndpoint = adminDashboardBaseUrl + '/label/group';

export const ftpSubscriptionEndpoint = adminDashboardBaseUrl + '/ftp/subscription';

export const ftpEndpoint = adminDashboardBaseUrl + '/ftp/data';

export const ftpDirectoryEndpoint = adminDashboardBaseUrl + '/ftp/directory';

export const adminFtpEndpoint = adminDashboardBaseUrl + '/ftp';

export const clientSystemBaseEndpoint = adminDashboardBaseUrl + '/client/systems';

export const clientSystemList = clientSystemBaseEndpoint + '/{clientId}/list';

export const systemConfigurationEndpoint = adminDashboardBaseUrl + '/system-configurations';

export const saveNetworkEndpoint = adminDashboardBaseUrl + '/network';

export const systemConfigurationDataTableEndpoint =
    adminDashboardBaseUrl + '/system-configurations/data-table';

export const systemConfigurationListEndpoint =
    adminDashboardBaseUrl + '/system-configurations/list';

export const systemEndpoint = adminDashboardBaseUrl + '/systems';
export const networkSystemEndpoint = adminDashboardBaseUrl + '/network';

export const systemDataTableEndpoint = systemEndpoint + '/data-table';

export const systemListEndpoint = systemEndpoint + '/list';

export const layoutEndpoint = adminDashboardBaseUrl + '/layouts';

export const fieldTypeEndpoint = adminDashboardBaseUrl + '/define-field/field-types';

export const fieldEndpoint = adminDashboardBaseUrl + '/define-field';

export const fieldListEndpoint = fieldEndpoint + '/data-table';

export const defineFieldListEndpoint = fieldEndpoint + '/list';

export const defineFieldByFileTypeListEndpoint = fieldEndpoint + '/list/{fileTypeId}';

export const referenceCommonEndpoint = adminDashboardBaseUrl + '/references';

export const referenceListEndpoint = referenceCommonEndpoint + '/list';

export const referenceClassificationListEndpoint = referenceCommonEndpoint + '/classification-list';

export const templateEndpoint = adminDashboardBaseUrl + '/templates';

export const uploadFileEndpoint = adminDashboardBaseUrl + '/uploads';

export const uploadGlobalFileEndpoint = adminDashboardBaseUrl + '/uploads/global-file';

export const uploadClientReferenceEndpoint = adminDashboardBaseUrl + '/uploads/client-reference';

export const filesListEndpoint = adminDashboardBaseUrl + '/files/data-table';

export const fileExactMatchEndpoint = adminDashboardBaseUrl + '/files-linking/exactmatch';

export const fileWordMatchEndpoint = adminDashboardBaseUrl + '/files-linking/wordmatch';

export const fileSectionSaveEndpoint = referenceCommonEndpoint + '/file-section-add';

export const fileSectionUpdateEndpoint = referenceCommonEndpoint + '/file-section-edit';

export const fileSectionDeleteEndpoint = referenceCommonEndpoint + '/file-section-delete';

export const templateListEndpoint = uploadFileEndpoint + '/templates/data-table';

export const uploadFileListEndpoint = uploadFileEndpoint + '/data-table';

export const uploadFilesEndpoint = uploadFileEndpoint + '/file/data-table';

export const splittedFilesListEndpoint = uploadFileEndpoint + '/splitted/files/data-table';

export const createdFilesListEndpoint = uploadFileEndpoint + '/created/files/data-table';

export const updatedFilesListEndpoint = uploadFileEndpoint + '/updated/files/data-table';

export const sectionsTableDataEndpoint = adminDashboardBaseUrl + '/section/data-table';

export const fieldsListEndpoint = adminDashboardBaseUrl + '/define-field/list';

export const snowflakeFieldsListEndpoint = adminDashboardBaseUrl + '/define-field/snowflake/list';

export const allFieldListEndpoint = adminDashboardBaseUrl + '/define-field/snowflake/list';

export const mappingTemplateByEndPoint = adminDashboardBaseUrl + '/mapping/category/template';

export const listOfFieldsBySectionIds =
    adminDashboardBaseUrl + '/define-field/listOfFieldBySectionIds';

export const listOfFieldsByFileId = adminDashboardBaseUrl + '/define-field/listOfFieldsByFileId';

export const listOfFieldsByFileIds = adminDashboardBaseUrl + '/define-field/listOfFieldsByFileIds';

export const listOfDefineFields = adminDashboardBaseUrl + '/define-field/field-label';

export const fileCommonEndpoint = adminDashboardBaseUrl + '/files';

export const sectionCommonEndpoint = adminDashboardBaseUrl + '/section';

export const sectionByFieldIdEndpoint = adminDashboardBaseUrl + '/section/listOfSectionByFileIds';

export const filesEndpoint = adminDashboardBaseUrl + '/files';

export const classificationFileEndpoint = '/files';

export const roleEndpoint = adminDashboardBaseUrl + '/role';

export const actionEndpoint = adminDashboardBaseUrl + '/action';

export const eventField = adminDashboardBaseUrl + '/define-field/getFieldsByStandardFileType';

export const eventEndpoint = adminDashboardBaseUrl + '/event';

export const actionFormEndpoint = adminDashboardBaseUrl + '/actionForm';

export const phaseEndpoint = adminDashboardBaseUrl + '/phase';

export const formEndpoint = adminDashboardBaseUrl + '/form';
export const projectEndpoint = adminDashboardBaseUrl + '/project';
export const taskEndpoint = adminDashboardBaseUrl + '/task';

export const taskDataTableEndpoint = adminDashboardBaseUrl + '/task/data-table';

export const taskAvailableEndpoint = adminDashboardBaseUrl + '/available-task';

export const workProductionEndpoint = adminDashboardBaseUrl + '/work/production';

export const productionSummaryDetailsEndpoint = adminDashboardBaseUrl + '/work/production/details';

export const taskLogSaveEndpoint = adminDashboardBaseUrl + '/task-log';

export const requestSupportSaveEndpoint = adminDashboardBaseUrl + '/requestSupport';

export const batchEndpoint = adminDashboardBaseUrl + '/batch';

export const batchUserEndpoint = adminDashboardBaseUrl + '/batch-work/client';

export const peopleEndpoint = adminDashboardBaseUrl + '/people';

export const snapshotEndpoint = adminDashboardBaseUrl + '/snapshot';

export const measureEndpoint = adminDashboardBaseUrl + '/measure';

export const analyticsMeasureEndpoint = clientBaseUrl + '/measure';

export const batchWorkEndpoint = adminDashboardBaseUrl + '/batch-work';

export const batchAvailableEndpoint = batchEndpoint + '/availableList';

export const batchOpenEndpoint = batchEndpoint + '/openList';

export const batchRemoveEndpoint = batchEndpoint + '/remove';

export const batchClosedEndpoint = batchEndpoint + '/closedList';

export const batchManageEndpoint = batchEndpoint + '/manageData';

export const batchManageWeekDetailsEndpoint = batchEndpoint + '/manage/week-details';

export const manageUserDetailsEndpoint = batchEndpoint + '/manage/userBatches';

export const stagingEndpoint = adminDashboardBaseUrl + '/staging/field';

export const analyticsOverviewTableDataEndpoint =
    adminDashboardBaseUrl + '/analytics/overview/list';

export const analyticsListViewEndpoint = adminDashboardBaseUrl + '/analytics/client/overview';
export const analyticsNetworkListViewEndpoint =
    adminDashboardBaseUrl + '/analytics/network/overview';
export const analyticsTriggerEndpoint =
    adminDashboardBaseUrl + '/analytics/network/trigger-analytics';

export const analyticsClientTriggerEndpoint = '/trigger-analytics/network';
export const analyticsListTableDataEndpoint = adminDashboardBaseUrl + '/analytics/list/data-table';

export const analyticsMetricsTableDataEndpoint =
    adminDashboardBaseUrl + '/analytics/metrics/data-table';

export const analyticsListCommonEndpoint = adminDashboardBaseUrl + '/analytics/list';

export const analyticsMetricsCommonEndpoint = adminDashboardBaseUrl + '/analytics/metrics';

export const analyticsV2ListCommonEndpoint = adminDashboardBaseUrl + '/analytics/list';

export const analyticsDashboardCommonEndpoint = adminDashboardBaseUrl + '/analytics/dashboard';

export const analyticsDashboardMetricsListEndPoint =
    adminDashboardBaseUrl + '/analytics/metrics/list';

export const analyticsDashboardVisualsListEndPoint =
    adminDashboardBaseUrl + '/analytics/visuals/list';

export const analyticsDashboardListEndPoint = adminDashboardBaseUrl + '/analytics/list';

export const analyticsDashboardTableDataEndpoint = analyticsDashboardCommonEndpoint + '/data-table';

export const analyticsViewTableDataEndpoint = adminDashboardBaseUrl + '/analytics/view/data-table';

export const analyticsViewCommonEndpoint = adminDashboardBaseUrl + '/analytics/view';

export const clientMetadataCommonEndpoint = adminDashboardBaseUrl + '/client/metadata';
export const networkMetadataCommonEndpoint = adminDashboardBaseUrl + '/network/metadata';

export const clientMetaDataList = clientMetadataCommonEndpoint + '/list';

export const clientPredictionList = adminDashboardBaseUrl + '/client/prediction/{clientId}';

export const clientPredictionRetrainModel =
    adminDashboardBaseUrl + '/client/prediction/{clientId}/retrainModel/{predictionId}';

export const clientPredictionDeployModel =
    adminDashboardBaseUrl + '/client/prediction/{clientId}/deployModel/{predictionId}';

export const clientPredictionTrainData =
    adminDashboardBaseUrl + '/client/prediction/{clientId}/trainData/{predictionId}';

export const clientListCommonEndpoint = adminDashboardBaseUrl + '/client/list';

export const clientListTableDataEndpoint = clientListCommonEndpoint + '/data-table';

export const clientListAnalyticsEndpoint = clientListCommonEndpoint + '/analytics';

export const analyticsDashboardListEndpoint = analyticsDashboardCommonEndpoint + '/list';

export const clientInsightsCommonEndpoint = adminDashboardBaseUrl + '/client/insights';

export const clientInsightsTableDataEndpoint = clientInsightsCommonEndpoint + '/data-table';

export const searchStatusEndpoint = adminDashboardBaseUrl + '/meta-data/status/active/list';

export const getStandardFilesEndpoint = adminDashboardBaseUrl + '/standard/files/list';

export const getStandardFileFieldsEndpoint = adminDashboardBaseUrl + '/standard/files/fieldlist';

export const getFileInstanceByFileIdEndpoint = adminDashboardBaseUrl + '/files/file-instance';

export const getFileInstanceBySectionEndpoint =
    adminDashboardBaseUrl + '/files/file-instance-section';

export const insertFieldDecryptionForFile = adminDashboardBaseUrl + '/files/addDecryptionHistory';

export const getFieldDecryptionForFile = adminDashboardBaseUrl + '/files/getDecryptionHistory';

export const getStatusOptionByStatusIdEndpoint =
    adminDashboardBaseUrl + '/meta-data/status/status-option';

export const analyticsViewsListEndpoint = adminDashboardBaseUrl + '/analytics/view/list';

export const analyticsVisualsCommonEndpoint = adminDashboardBaseUrl + '/analytics/visuals';

export const analyticsVisualsTypeList = adminDashboardBaseUrl + '/visual/type/list';

export const analyticsVisualsTableDataEndpoint = analyticsVisualsCommonEndpoint + '/data-table';
export const analyticsVisualsTableDataEndpointV2 = analyticsVisualsCommonEndpoint + '/data-table';

export const analyticsVisualListEndpoint = analyticsVisualsCommonEndpoint + '/list';

export const getLookupInstanceFromLookupMetadata =
    adminDashboardBaseUrl + '/client/{clientId}/lookup-instance';

export const clientAnalyticsChartEndpoint = clientBaseUrl;

export const clientFilterDataByDashboardId = clientBaseUrl;

export const clientReferenceBaseUrl = adminDashboardBaseUrl + '/client/references';
export const getclientReferenceBaseUrl = clientReferenceBaseUrl + '/list';
export const getclientReferenceClassificationBaseUrl =
    clientReferenceBaseUrl + '/classification-list';
export const getclientReferenceExactMatchBaseUrl =
    clientReferenceBaseUrl + '/linking-logic/exactmatch';
export const getclientReferenceWordMatchBaseUrl =
    clientReferenceBaseUrl + '/linking-logic/wordmatch';
export const getReferenceLinkingBaseUrl = clientReferenceBaseUrl + '/linking';
export const getReferenceLinkingLinkedBaseUrl = clientReferenceBaseUrl + '/linking/linked';
export const getReferenceLinkingMissingLinkedBaseUrl =
    clientReferenceBaseUrl + '/linking/missing-linked';
export const getReferenceLinkingMissingLinkBaseUrl = clientReferenceBaseUrl + '/reference-linking';
export const deleteClientUrl = adminDashboardBaseUrl + '/client/delete';

// discussions
export const discussionURL = adminDashboardBaseUrl + '/discussion';

//Network
export const networkBaseUrl = adminDashboardBaseUrl + '/network';

//Cohorts
const cohortsBaseUrl = 'https://th-cohorts.azurewebsites.net/';

export const clientAnalyticsBubbleChartEndpoint = cohortsBaseUrl + 'cohorts_graph';

export const segmentWorkMapperEndpoint = cohortsBaseUrl + 'segment_work_mapper';

export const getSegmentChartMapper = cohortsBaseUrl + 'segment_chart_mapper';

export const getBubbleChartFileInstance = cohortsBaseUrl + 'segment_fileinstance_paginate';

export const documentTypeGet = adminDashboardBaseUrl + '/document/type/data-table';

export const documentAllTypeGet = adminDashboardBaseUrl + '/document/type/list';

export const documentTypeAdd = adminDashboardBaseUrl + '/document/type';

export const documentTypeUpdate = adminDashboardBaseUrl + '/document/type/{documentTypeId}';

export const documentTypeDelete = adminDashboardBaseUrl + '/document/type/{documentTypeId}';

export const documentTemplateAdd = adminDashboardBaseUrl + '/document/template';

export const documentTemplateGet = adminDashboardBaseUrl + '/document/template/{templateId}';

export const documentTemplateDelete = adminDashboardBaseUrl + '/document/template/{templateId}';

export const documentAllTemplateGet = adminDashboardBaseUrl + '/document/template/data-table';

export const documentTemplateUpdate = adminDashboardBaseUrl + '/document/template/{templateId}';

export const documentUpload =
    adminDashboardBaseUrl + '/document/template/{clientId}/add-document-file';

export const documentMetadata =
    adminDashboardBaseUrl + '/document/template/{clientId}/document-metadata';

export const documentFiles = adminDashboardBaseUrl + '/document/template/downloadDocuments';

export const documentFilesUrls = adminDashboardBaseUrl + '/document/template';

export const documentTrainDataGet =
    adminDashboardBaseUrl + '/document/template/{documentId}/train-data';
export const documentTrainDataCreate =
    adminDashboardBaseUrl + '/document/template/{documentId}/train-data';
export const documentTrainDataUpdate =
    adminDashboardBaseUrl + '/document/template/{documentId}/train-data';
export const documentTrainDataDelete =
    adminDashboardBaseUrl + '/document/template/{documentId}/train-data/{trainDataId}';

export const documentElementTableView = adminDashboardBaseUrl + '/document/element/data-table';
export const documentAllElementGet = adminDashboardBaseUrl + '/document/element/list';
export const documentElementGet = adminDashboardBaseUrl + '/document/element/{documentElementId}';
export const documentElementAddFile =
    adminDashboardBaseUrl + '/document/element/{documentElementId}/add-file';
export const documentElementCreate = adminDashboardBaseUrl + '/document/element';
export const documentElementUpdate =
    adminDashboardBaseUrl + '/document/element/{documentElementId}';
export const documentElementDelete =
    adminDashboardBaseUrl + '/document/element/{documentElementId}';
export const documentElementFileDownload =
    adminDashboardBaseUrl + '/document/element/{documentElementId}/download/{fileId}';
export const batchClusterEndpoint = adminDashboardBaseUrl + '/batchWork/trigger';
export const batchClusterAutoTrigger = adminDashboardBaseUrl + '/batch/trigger-auto-batching';
export const elementTypeEndpoint = adminDashboardBaseUrl + '/document/element/element-types';
export const wrapperTrigger = adminDashboardBaseUrl + '/metadata/trigger';

// get the local storage data
export const localStorage = baseUrl + '/auth/localStorage';
// user login
export const userLogin = baseUrl + '/auth/login';
// user logout
export const userLogout = baseUrl + '/auth/logout';
// user 2 factor auth
export const TwoFactorAuth = baseUrl + '/auth/2factorVerification';
// get user role permission
export const getRolePermission = baseUrl + '/auth/getUserPermission';
// user creation / Update

// verify user
export const verifyUser = baseUrl + '/auth/verify-user';

export const userPassword = baseUrl + '/auth/changePassword';

export const setUserPassword = baseUrl + '/auth/setPassword';

export const checkEmailExistence = baseUrl + '/email/checkExistence';

export const sendPasswordResetEmail = baseUrl + '/email/sendResetPasswordLink';

export const userClientCredUrl = adminDashboardBaseUrl + '/client';

export const userNetworkCredUrl = adminDashboardBaseUrl + '/network';

export const users = adminDashboardBaseUrl + '/users';

export const statusMetadataCommonEndpoint = adminDashboardBaseUrl + '/meta-data/status';

export const statusMetadataListEndpoint = statusMetadataCommonEndpoint + '/data-table';

export const kibanaLoginUrl = 'http://graph.transformhealth.ai:9200/internal/security/login';

export const kibanaHomePageUrl = 'http://graph.transformhealth.ai:9200/app/home#/';

// copilots
export const copilotsEndPoint = adminDashboardBaseUrl + '/copilot';

export const copilotsNameSpaceList = copilotsEndPoint + '/namespace/data-table';
export const copilotsNameSpaceSave = copilotsEndPoint + '/namespace';
export const copilotsNamespaceUpload = copilotsEndPoint + '/namespace/upload';
export const copilotsNamespaceDownload = copilotsEndPoint + '/namespace/download';

export const copilotsPersonaList = copilotsEndPoint + '/persona/data-table';
export const copilotsPersonaSave = copilotsEndPoint + '/persona';

export const copilotsInterviewList = copilotsEndPoint + '/interview/data-table';
export const copilotsInterviewLSave = copilotsEndPoint + '/interview';

export const copilotsFlowList = copilotsEndPoint + '/flow/data-table';
export const copilotsFlowSave = copilotsEndPoint + '/flow';
export const copilotsGetProcessType = copilotsEndPoint + '/flow/process-type-list';

export const copilotsPersonList = copilotsEndPoint + '/persona/active-list';
export const copilotsNamespaceList = copilotsEndPoint + '/namespace/active-list';

export const copilotsContentList = copilotsEndPoint + '/content/data-table';
export const copilotsContentSave = copilotsEndPoint + '/content';
export const copilotsContentUpload = copilotsEndPoint + '/content/upload-thumb';

export const copilotsAssetList = copilotsEndPoint + '/asset/data-table';
export const copilotsAssetSave = copilotsEndPoint + '/asset';

export const copilotsActivityList = copilotsEndPoint + '/activity/data-table';
export const copilotsActivity = copilotsEndPoint + '/activity/detail';

// data overview predecessor field id endpoints
export const calculationPredecessorFieldIdsEndpoint =
    adminDashboardBaseUrl + '/calculation/meta-data/source-fields/';
export const flagPredecessorFieldIdsEndpoint =
    adminDashboardBaseUrl + '/meta-data/flag/source-fields/';

export const requestHeaderDetails = {
    headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
    }),
};
export const textRequestHeader = {
    headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*', 'Content-Type': 'text/plain' }),
};
export const requestPdfHeaderDetails = {
    headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        responseType: 'application/pdf',
    }),
};

export const kibanaHeaderDetails = {
    headers: new HttpHeaders({
        'kbn-version': '7.11.1',
        'kbn-xsrf': 'true',
        'Content-Type': 'application/json',
        Connection: 'keep-alive',
        Authorization: 'Basic UHJhdmVlbjpwcmF2ZWVuQDEyMw==',
    }),
};
