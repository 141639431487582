import { DOCUMENT } from '@angular/common';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthenticationService } from '@root/_services/authentication.service';
import { LocalStorageProviderService } from '@root/_services/local-storage-provider.service';
import { environment } from '@root/environments/environment';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
    selector: 'core-root',
    templateUrl: './root.component.html',
    styleUrls: ['./root.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class RootComponent {
    constructor(
        @Inject(DOCUMENT) private document: Document,
        private titleService: Title,
        private localStorage: LocalStorageProviderService,
        private ngxPermissionsService: NgxPermissionsService,
        private authService: AuthenticationService
    ) {
        const window = this.document.defaultView;

        if (window) {
            this.titleService.setTitle(environment.title);
            const pathname = window.location.pathname;

            // Get current URL
            const currentUrl = window.location.href;

            // Check if URL contains '/auth/set-password' or '/auth/verify-user'
            if (currentUrl.includes('/auth/set-password') || currentUrl.includes('/auth/verify-user')) {
                return; // Skip further logic
            }
            
            // Allow processing on '/auth/login' route regardless of query params
            if (pathname === '/auth/login') {
                return; // Skip further authentication checks
            }

            if (this.localStorage.isLocalStorageValid()) {
                const permissions = this.localStorage.getItem('permissions');
                this.ngxPermissionsService.loadPermissions(permissions);
            } else {
                this.authService.logout();
                window.location.href = '/auth/login';
            }
        } else {
            console.error('The windows object is not available.');
        }
    }
}
