import {
    AfterContentChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { ChatService } from './services/chat.service';
export type MessageDirection = 'inbound' | 'outbound';

export interface ConversationMessage {
    content: string;
    direction: MessageDirection;
    timestamp: string;
    isResponseLoader?: boolean;
}

export type ChatHistorySource = 'system' | 'user';
export interface ChatHistoryItem {
    message: string;
    source: ChatHistorySource;
}

@Component({
    selector: 'conversation',
    templateUrl: './conversation.component.html',
    styleUrls: ['conversation.component.scss'],
})
export class ConversationComponent implements OnInit {
    @Input({ required: true }) title!: string;
    @Output() messageSent = new EventEmitter<string>();
    constructor(
        private chatService: ChatService,
        private cdr: ChangeDetectorRef
    ) {}
    requestHistory: string[] = [];
    currentRequestHistoryIndex = -1;

    messageHistory: ConversationMessage[] = [];
    outgoingMessageContent = '';
    showScrollToLatest = false;

    @ViewChild('messagesContainer') messagesContainer!: ElementRef;
    @ViewChild('conversationContainer') conversationContainer!: ElementRef;
    @ViewChild('scrollToLatestButton') scrollToLatestButton!: ElementRef;
    @ViewChild('conversationBody') conversationBody!: ElementRef;
    @ViewChild('growWrapper') growWrapper!: ElementRef;

    chatHistory: ChatHistoryItem[] = [];
    scrolled = false;
    ngOnInit(): void {
        // Fetch chat history from service when component initializes
        const getChatHistory = this.chatService.getChatHistory();

        if (getChatHistory.length > 0) {
            this.showScrollToLatest = true;
            // merging chat history with messageHistory ang
            this.messageHistory = [...getChatHistory, ...this.messageHistory];
        }
    }

    onSend() {
        this.currentRequestHistoryIndex = -1;

        // add the outgoing message to the request history at the top of the array
        this.requestHistory = [this.outgoingMessageContent, ...this.requestHistory];

        // add the outgoing message to the bottom of the chat history
        this.chatHistory.push({
            message: this.outgoingMessageContent,
            source: 'user',
        });

        // store messages to chat history
        this.chatService.addMessageToChatHistory(
            this.outgoingMessageContent,
            'outbound',
            new Date().toISOString()
        );

        this.messageHistory.push({
            content: this.outgoingMessageContent,
            direction: 'outbound',
            timestamp: new Date().toISOString(),
        });

        this.messageHistory.push({
            content: 'I am a response loader',
            direction: 'inbound',
            timestamp: new Date().toISOString(),
            isResponseLoader: true,
        });

        // notify parent component that a message has been sent
        this.messageSent.emit(this.outgoingMessageContent);

        // clear the outgoing message content, this will also clear the input field
        this.outgoingMessageContent = '';
        this.growWrapper.nativeElement.dataset.replicatedValue = '';

        // trigger scroll function
        this.scrollToBottom();
    }

    public addResponse(message: string) {
        // remove all response loaders
        this.messageHistory = this.messageHistory.filter(msg => !msg.isResponseLoader);

        this.messageHistory.push({
            content: message,
            direction: 'inbound',
            timestamp: new Date().toISOString(),
        });

        // store messages to chat history
        this.chatService.addMessageToChatHistory(message, 'inbound', new Date().toISOString());
        // trigger scroll function
        this.scrollToBottom();
        // add the response to the chat history
        this.chatHistory.push({
            message,
            source: 'system',
        });
    }

    public getChatHistory(): ChatHistoryItem[] {
        return this.chatHistory;
    }

    scrollToBottom(): void {
        try {
            if (this.conversationBody) {
                const element = this.conversationBody.nativeElement;
                const scrollOptions = {
                    top: element.scrollHeight,
                    left: 0,
                    behavior: 'smooth',
                };

                setTimeout(() => {
                    element.scroll(scrollOptions);
                }, 100);
            }
        } catch (err) {
            console.error('Scroll to bottom failed:', err);
        }
    }

    navigateRequestHistory(key: string) {
        if (key === 'up') {
            if (this.currentRequestHistoryIndex < this.requestHistory.length - 1) {
                this.currentRequestHistoryIndex++;
            }
        } else if (key === 'down') {
            if (this.currentRequestHistoryIndex > -1) {
                this.currentRequestHistoryIndex--;
            }
        }

        if (this.currentRequestHistoryIndex >= 0) {
            this.outgoingMessageContent = this.requestHistory[this.currentRequestHistoryIndex];
        } else {
            this.outgoingMessageContent = '';
        }
    }

    // this function is used to scroll latest part of the conversation
    onConversationScroll(): void {
        this.updateScrollToLatestVisibility();
    }

    // this function helps to enable/disable scroll interface to user
    updateScrollToLatestVisibility(): void {
        const element = this.conversationBody.nativeElement;

        // An element's scrollTop value is a measurement of the distance from
        // the element's top to its topmost visible content. When an element's content
        // does not generate a vertical scrollbar, then its scrollTop value is 0.
        const scrollTop = element.scrollTop;

        // The scrollHeight value is equal to the minimum height the element would
        // require in order to fit all the content in the viewport without using a vertical scrollbar.
        const scrollHeight = element.scrollHeight;

        // The clientHeight property returns the viewable height of an element in pixels,
        // including padding, but not the border, scrollbar or margin.
        const clientHeight = element.clientHeight;

        // determine if the visible area is within 50 pixels of the bottom of the total content
        const atBottom = scrollHeight - scrollTop - clientHeight < 50;

        // show the button if the top to the visible content is above the halfway point
        // and the visible content is not at the bottom of the total content
        const showScrollToLatest = !atBottom;

        // Enable the button if the user has not reached the bottom of the scrollable area
        this.showScrollToLatest = showScrollToLatest;
    }
}
