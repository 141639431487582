import { Injectable } from '@angular/core';
import { ConversationMessage, MessageDirection } from '../conversation.component';

@Injectable({
    providedIn: 'root',
})
export class ChatService {
    messageHistory: ConversationMessage[] = [];

    // Method to add a message to chat history
    addMessageToChatHistory(message: string, directionType: MessageDirection, timestamp: string) {
        this.messageHistory.push({
            content: message,
            direction: directionType,
            timestamp: timestamp,
        });
    }

    // Method to get the chat history
    getChatHistory(): ConversationMessage[] {
        return this.messageHistory;
    }
}
