import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxPermissionsModule } from 'ngx-permissions';

/* Third Party */
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IconsModule } from './icons/icons.module';

const thirdParty = [IconsModule, NgbModule];

/* Containers */
import * as appCommonContainers from './containers';

/* Components */
import * as appCommonComponents from './components';

/* Guards */
import * as appCommonGuards from './guards';

/* Services */
import * as appCommonServices from './services';
import { NgButtonComponent } from './components/ng-button/ng-button.component';
import { CheckboxModule } from 'primeng/checkbox';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MultiSelectModule } from 'primeng/multiselect';
import { NgChartsModule } from 'ng2-charts';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { DividerModule } from 'primeng/divider';
import { CalendarModule } from 'primeng/calendar';
import { MessagesModule } from 'primeng/messages';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { RadioButtonModule } from 'primeng/radiobutton';
import { NgSelectModule } from '@ng-select/ng-select';
import { AccordionModule } from 'primeng/accordion';
import { TooltipModule } from 'primeng/tooltip';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { BreadCrumbComponent } from './components/bread-crumb/bread-crumb.component';
import { DialogModule } from 'primeng/dialog';
import { DiscussionCommentBoxComponent } from './components/discussion-comment-box/discussion-comment-box.component';
import { ListboxModule } from 'primeng/listbox';
import { TableChartElementComponent } from './components/table-chart-element/table-chart-element.component';

// Pipes
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { PcsCopilotService } from './services/pcs-copilot.service';
import { pcsCopilotServiceFactory } from './services/pcs-copilot-service-factory';

@NgModule({
    imports: [
        NgSelectModule,
        CommonModule,
        RouterModule,
        ...thirdParty,
        CheckboxModule,
        ReactiveFormsModule,
        FormsModule,
        MessagesModule,
        AccordionModule,
        TooltipModule,
        TableModule,
        RadioButtonModule,
        BreadcrumbModule,
        PaginatorModule,
        DividerModule,
        CalendarModule,
        AutoCompleteModule,
        MultiSelectModule,
        NgxPermissionsModule.forChild(),
        NgChartsModule,
        NgScrollbarModule,
        DialogModule,
        ListboxModule,
    ],
    providers: [
        ...appCommonServices.services,
        ...appCommonGuards.guards,
        {
            provide: PcsCopilotService,
            useFactory: pcsCopilotServiceFactory,
        }
    ],
    declarations: [
        ...appCommonContainers.containers,
        ...appCommonComponents.components,
        NgButtonComponent,
        BreadCrumbComponent,
        DiscussionCommentBoxComponent,
        TableChartElementComponent,
        SafeHtmlPipe],
    exports: [
        ...appCommonContainers.containers,
        ...appCommonComponents.components,
        ...thirdParty,
        CheckboxModule,
        NgSelectModule,
        NgxPermissionsModule,
        SafeHtmlPipe
    ],
})
export class SharedComponentModule { }
