import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';

import { RootModule } from './app/root/root.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

const rumInitConfiguration: RumInitConfiguration = {
    applicationId: environment.datadogApplicationId,
    clientToken: environment.datadogClientToken,
    site: environment.datadogSite,
    service: environment.datadogService,
    env: environment.datadogEnv,
    version: environment.applicationVersion,
    sessionSampleRate: environment.datadogSessionSampleRate,
    sessionReplaySampleRate: environment.datadogSessionReplaySampleRate,
    trackResources: environment.datadogTrackResources,
    trackLongTasks: environment.datadogTrackLongTasks,
    trackUserInteractions: environment.datadogTrackUserInteractions,
    defaultPrivacyLevel: environment.datadogDefaultPrivacyLevel,
    allowedTracingUrls: [environment.baseUrl, (url: string) => url.startsWith(environment.baseUrl)],
    traceSampleRate: environment.datadogTraceSampleRate,
    compressIntakeRequests: environment.datadogCompressIntakeRequests,
    storeContextsAcrossPages: environment.datadogStoreContextsAcrossPages,
};

// JPF (4-18-2024): Disabled for now. Generally unused feature at this point. Should accelerate application
// at runtime and reduce network traffic.
// datadogRum.init(rumInitConfiguration);

datadogLogs.init({
    clientToken: environment.datadogClientToken,
    site: environment.datadogSite,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
});

platformBrowserDynamic()
    .bootstrapModule(RootModule)
    .catch(err => {
        console.error('Application Initialization Error:', err);
    });
