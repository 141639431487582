import { Component } from '@angular/core';
import { LoaderService } from './loader.service';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.css'],
})
export class CommonLoaderComponent {
    constructor(private _loaderService: LoaderService) {}

    isVissible(): boolean {
        return this._loaderService.getIsLoaderVisible();
    }
}
