import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { environment } from '@root/environments/environment';

import { LoggingService } from './logging.service';
import { ConfirmationService } from './confirmation.service';

export const LOCAL_STORAGE_APPLICATION_VERSION_KEY = 'applicationVersion';

/**
 * This service is responsible for monitoring the application version as deployed
 * on the server and notifying the user if a new version is available to prompt
 * a page reload.
 */
@Injectable({
    providedIn: 'root',
})
export class VersionCheckService {
    private versionChangeSubject = new Subject<void>();

    versionChange$ = this.versionChangeSubject.asObservable();

    constructor(
        private loggingService: LoggingService,
        private confirmationService: ConfirmationService
    ) {}

    /**
     * Prompts the user to reload the page if a new version is available.
     * @param oldVersion The version of the application that is currently running.
     * @param newVersion The version of the application that is deployed on the server.
     */
    showReloadAlert(oldVersion: string, newVersion: string) {
        this.loggingService.debug(
            `Prompting user for reload. Running version: ${oldVersion}, New version: ${newVersion}`
        );
        this.loggingService.debug(
            `Session started with version: ${environment.applicationVersion}`
        );

        // Show the reload confirmation dialog
        // Note that the reload callback is bound to this class so that it may use
        // the logging service to log the reload event
        this.confirmationService.showReloadConfirmation(
            newVersion,
            this.reloadConfirmed.bind(this)
        );
    }

    /**
     * Reloads the page and updates the stored application version. This method
     * is used as a callback for the reload confirmation dialog.
     * @param newVersion
     */
    reloadConfirmed(newVersion: string) {
        this.loggingService.info(
            `User accepted reload prompt. Reloading and updating to version: ${newVersion}`
        );

        // Update the stored version in localStorage
        localStorage.setItem(LOCAL_STORAGE_APPLICATION_VERSION_KEY, newVersion);

        // allow local storage to update
        this.sleep(500).then(() => {
            window.location.reload();
        });
    }

    sleep(ms: number) {
        let resolve: { (value: unknown): void };
        const promise = new Promise(_resolve => {
            resolve = _resolve;
        });
        setTimeout(() => resolve(ms), ms); // allow storage to update
        return promise;
    }

    triggerVersionChange() {
        this.versionChangeSubject.next();
    }
}
