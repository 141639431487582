import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@root/environments/environment';
import { Observable } from 'rxjs';

import { RxStomp } from '@stomp/rx-stomp';

import { ChatHistoryItem } from '@app/modules/shared/components';

interface CopilotRequest {
    message: string;
}

export interface DeveloperCopilotRequest {
    message: string;
    chatHistory: ChatHistoryItem[];
    networkId: number | null;
    clientId: number | null;
    userId: number | null;
}

export interface CopilotResponse {
    message: string;
    userId: number;
    authToken: string;
    username?: string;
    clientId?: number;
}

export interface OutgoingWebSocketCopilotMessage {
    userId: number;
    authToken: string;
    clientId?: number; // this is not needed for the Angular app, will be required for the Flutter app
    message: string;
    chatHistory: ChatHistoryItem[];
}

@Injectable({
    providedIn: 'root',
})
export class PcsCopilotService extends RxStomp {
    private readonly baseUrl = environment.baseUrl;
    private readonly apiPath = '/api/v1/admin/pcscopilot';

    private readonly developerCopilotEndpoint = this.baseUrl + this.apiPath + '/developer-chat';

    private readonly generalNamespaceCopilotEndpoint =
        this.baseUrl + this.apiPath + '/global-namespace-chat';

    constructor(private http: HttpClient) {
        super();
    }

    public getDeveloperCopilotResponse(request: DeveloperCopilotRequest): Observable<any> {
        return this.http.post(this.developerCopilotEndpoint, request, {
            responseType: 'text',
        });
    }

    public getGeneralNamespaceChatResponse(message: string): Observable<any> {
        // create a request object with the message
        const request: CopilotRequest = { message };

        // post the request to the general namespace copilot endpoint
        // expect a response in the form of a string
        return this.http.post(this.generalNamespaceCopilotEndpoint, request, {
            responseType: 'text',
        });
    }
}