import { inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { LocalStorageProviderService } from "@root/_services/local-storage-provider.service";
import { PcsCopilotService } from "./pcs-copilot.service";
import { rxStompConfig } from "./rx-stomp.config";

export function pcsCopilotServiceFactory(): PcsCopilotService {
    const httpClient = inject(HttpClient);
    const localStorageProviderService = inject(LocalStorageProviderService);

    const token = localStorageProviderService.getItem("currentUser").token;
    const headers = {
        Authorization: `Bearer ${token}`
    };

    rxStompConfig.connectHeaders = headers;

    const pcsCopilotService = new PcsCopilotService(httpClient);
    pcsCopilotService.configure(rxStompConfig);
    pcsCopilotService.activate();
    return pcsCopilotService;
}