import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
    EventEmitter,
    Output,
    ViewChild,
    TemplateRef,
} from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'ng-delete-modal',
    templateUrl: './delete-modal.component.html',
    styleUrls: ['delete-modal.component.scss'],
    providers: [NgbModalConfig, NgbModal],
})
export class NgDeleteModalComponent {
    @ViewChild('deleteAlert') alertModal?: TemplateRef<any>;
    @Output() deleteEvent = new EventEmitter();
    deleteModal: any;
    constructor(
        config: NgbModalConfig,
        private modalService: NgbModal
    ) {
        config.backdrop = 'static';
        config.keyboard = false;
    }

    deleteItem() {
        this.deleteEvent.emit();
    }
    open() {
        this.deleteModal = this.modalService.open(this.alertModal, {
            centered: true,
            windowClass: 'custom-class',
        });
    }
}
