import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ConversationMessage } from '../conversation.component';

@Component({
    selector: 'message',
    templateUrl: './message.component.html',
    styleUrls: ['message.component.scss'],
    encapsulation: ViewEncapsulation.None, // important to allow the styling of innerHtml injects markup
})
export class MessageComponent {
    @Input() message!: ConversationMessage;

    isInbound() {
        return this.message.direction === 'inbound';
    }

    isResponseLoader() {
        const loader = this.message.isResponseLoader;
        const result = loader === undefined || loader === false ? false : true;
        return result;
    }
}
