import { Injectable } from '@angular/core';
import { environment } from '@root/environments/environment';
import { AuthenticationService } from './authentication.service';
import { HttpClient } from '@angular/common/http';

import { frontendLoggingEndpoint, frontendTimingsEndpoint } from '@root/config/service-mapping';

import { datadogLogs } from '@datadog/browser-logs';

/**
 * The threshold for logging a warning for a slow HTTP request.
 * If the request takes longer than this threshold, a warning will be logged.
 */
const TIMING_WARNING_THRESHOLD = 1500;

/**
 * Enumeration of the log levels.
 * The log level is controlled by the logLevel property in the environment files.
 */
enum LogLevel {
    DEBUG = 0,
    INFO = 1,
    WARN = 2,
    LOG = 3,
    ERROR = 4,
}

/**
 * Request to send to the remote logging service.
 */
class RemoteLoggingRequest {
    severity: string | undefined;
    message: string | undefined;
    stacktrace: string | undefined;
    timestamp: string | undefined;
    applicationName: string | undefined;
    platform: string | undefined;
    userId: number | undefined;
    userClientId: number | undefined;
    userEmail: string | undefined;
    userName: string | undefined;
    userToken: string | undefined;
    environment: string | undefined;
}

/**
 * Request to send to the remote logging service for timing information.
 * This request extends the base request with timing information.
 */
class RemoteLoggingTimingRequest extends RemoteLoggingRequest {
    url: string | undefined;
    method: string | undefined;
    durationInMilliseconds: number | undefined;
}

/**
 * Logging service for the application.
 * This class provides a cental location for logging that can be
 * turned on and off at runtime. It is controlled by the logLevel
 * property in the environment files. This class also provides
 * a central location to wire up remote logging.
 */
@Injectable({
    providedIn: 'root',
})
export class LoggingService {
    private logLevel: LogLevel = LogLevel.DEBUG;

    constructor(
        private authService: AuthenticationService,
        private http: HttpClient
    ) {
        this.logLevel = LogLevel[environment.logLevel as keyof typeof LogLevel];
    }

    /**
     * Builds a request to send to the remote logging service.
     * @param message The message to send to the remote logging service.
     * @param logLevel The log level of the message.
     * @returns The built request.
     */
    private getRemoteLoggingRequest(message: string, logLevel: LogLevel): RemoteLoggingRequest {
        // Determine the environment that the application is running in.
        const env: string = environment.production ? 'prod' : 'qa';

        // Convert the log level to a string.
        const severity: string = LogLevel[logLevel];

        const request = new RemoteLoggingRequest();
        request.severity = severity;
        request.message = message;
        request.timestamp = new Date().toISOString();
        request.applicationName = 'admin-frontend';
        request.platform = 'web';
        request.environment = env;

        // If the user is logged in, add the user token to the request.
        const currentUser = this.authService.getCurrentUserValue();
        if (currentUser) {
            request.userId = currentUser.id;
            request.userClientId = currentUser.clientId;
            request.userEmail = currentUser.email;
            request.userName = currentUser.userName;
            request.userToken = currentUser.token;
        }

        return request;
    }

    /**
     * Builds a message with the associated parameters by converting
     * each parameter to a string and concatenating it to the message.
     * @param message The message to build.
     * @param params The parameters to include in the message.
     * @returns The built message.
     */
    private getMessage(message: string, params: string[]): string {
        let formattedMessage = message;

        // Build the message with the associated parameters.
        for (const p of params) {
            formattedMessage = formattedMessage.replace('{}', p);
        }

        return formattedMessage;
    }

    /**
     * Sends a log message to the remote logging service.
     * @param message The message to send to the remote logging service.
     * @param logLevel The log level of the message.
     * @param error The error to send to the remote logging service.
     * @param params The parameters to include in the message.
     */
    private sendRemoteLog(
        message: string,
        logLevel: LogLevel,
        error?: Error,
        ...params: string[]
    ): void {
        // If the user is not logged in, do not send the log to the remote logging service.
        const currentUser = this.authService.getCurrentUserValue();
        if (!currentUser) {
            return;
        }

        // Build the message with the associated parameters.
        const formattedMessage = this.getMessage(message, params);

        // Build the request to send to the remote logging service.
        const request = this.getRemoteLoggingRequest(formattedMessage, logLevel);

        // If an error was provided, add the stacktrace to the request.
        if (error) {
            request.stacktrace = error.stack;
        }

        // Send the request to the remote logging service.
        // Subscribe immediately to fire and forget.
        this.http.post(frontendLoggingEndpoint, request).subscribe();
    }

    /**
     * Logs a debug message to the remote logging service and to the console.
     * @param message The message to log to the console.
     * @param params The parameters to include in the message.
     */
    debug(message: string, ...params: string[]): void {
        if (this.logLevel > LogLevel.DEBUG) {
            return;
        }

        if (environment.enableConsoleLogging) {
            console.debug(message, ...params);
        }

        // this.sendRemoteLog(message, LogLevel.DEBUG, undefined, ...params);
        datadogLogs.logger.debug(message);
    }

    /**
     * Logs an error message to the remote logging service and to the console.
     * @param message The message to log to the console.
     * @param error The error to log to the console.
     */
    error(message: string, error: Error): void {
        if (this.logLevel > LogLevel.ERROR) {
            return;
        }

        if (environment.enableConsoleLogging) {
            console.error(message, error);
        }

        // this.sendRemoteLog(message, LogLevel.ERROR, error);
        datadogLogs.logger.error(message, undefined, error);
    }

    /**
     * Logs an info message to the remote logging service and to the console.
     * @param message The message to log to the console.
     * @param params The parameters to include in the message.
     */
    info(message: string, ...params: string[]): void {
        if (this.logLevel > LogLevel.INFO) {
            return;
        }

        if (environment.enableConsoleLogging) {
            console.info(message, ...params);
        }

        // this.sendRemoteLog(message, LogLevel.INFO, undefined, ...params);
        datadogLogs.logger.info(message);
    }

    /**
     * Logs a message to the remote logging service and to the console.
     * @param message The message to log to the console.
     * @param params The parameters to include in the message.
     */
    log(message: string, ...params: string[]): void {
        if (this.logLevel > LogLevel.LOG) {
            return;
        }

        if (environment.enableConsoleLogging) {
            console.log(message, ...params);
        }

        // this.sendRemoteLog(message, LogLevel.LOG, undefined, ...params);
        datadogLogs.logger.info(message);
    }

    /**
     * Logs a warning message to the remote logging service and to the console.
     * @param message The message to log to the console.
     * @param params The parameters to include in the message.
     * @returns
     */
    warn(message: string, ...params: string[]): void {
        if (this.logLevel > LogLevel.WARN) {
            return;
        }

        if (environment.enableConsoleLogging) {
            console.warn(message, ...params);
        }

        // this.sendRemoteLog(message, LogLevel.WARN, undefined, ...params);
        datadogLogs.logger.warn(message);
    }

    /**
     * Logs the timing of an HTTP request to the remote logging service and
     * to the console.
     * @param url The URL of the HTTP request.
     * @param method The HTTP method of the request.
     * @param durationInMilliseconds The duration of the request in milliseconds.
     * @param message The message to log to the console.
     * @param params The parameters to include in the message.
     */
    logRequestTimings(
        url: string,
        method: string,
        durationInMilliseconds: number,
        message: string,
        ...params: string[]
    ): void {
        // if the user is logged in, we won't have access to the timings endpoint
        // so don't log anything
        const currentUser = this.authService.getCurrentUserValue();
        if (currentUser) {
            return;
        }

        // Build the message with the associated parameters.
        const formattedMessage = this.getMessage(message, params);

        // Build the request to send to the remote logging service.
        const request = this.getRemoteLoggingRequest(formattedMessage, LogLevel.DEBUG);

        // Add the request timing information to the request.
        const timingRequest = request as RemoteLoggingTimingRequest;
        timingRequest.url = url;
        timingRequest.method = method;
        timingRequest.durationInMilliseconds = durationInMilliseconds;

        // Log to the console.
        if (durationInMilliseconds > TIMING_WARNING_THRESHOLD) {
            console.warn(formattedMessage);
        } else {
            console.info(formattedMessage);
        }

        // Send the request to the remote logging service.
        // Subscribe immediately to fire and forget.
        this.http.post(frontendTimingsEndpoint, timingRequest).subscribe();
    }
}
