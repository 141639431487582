import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
// import { InstancesChartData } from '@app/modules/client/analytics/models';
import 'chartjs-plugin-datalabels';

@Component({
    selector: 'doughnut-chart-element',
    templateUrl: './doughnut-chart-element.component.html',
    styleUrls: ['./doughnut-chart-element.component.scss'],
})
export class DoughnutChartElementComponent implements OnInit {
    @Input()
    instancesChartData?: any;
    @Input() visualConfigId?: string;
    public chartType: ChartType = 'doughnut';
    public donutChartData: any = [];

    barChartOptions: ChartOptions = {} as any;
    barInput: any[] = [];

    @Output() chartClickedEvent = new EventEmitter<any>();
    ngOnInit(): void {
        this.barChartOptions = {
            // tooltips: {
            //   enabled: true,
            //   mode: 'single',
            //   titleAlign: 'center',
            //   bodyFontSize: 10,
            //   caretSize: 4,
            //   titleMarginBottom: 2,
            //   titleFontSize: 12,
            //   xPadding: 7,
            //   yPadding: 7,
            //   position: 'nearest',
            // },
            scales: {
                x: {
                    type: 'linear',
                    grid: {
                        color: 'rgba(0, 0, 0, 0)',
                        // zeroLineColor: 'white',
                    },
                    ticks: {
                        display: false,
                    },
                },
                y: {
                    type: 'linear',
                    grid: {
                        color: 'rgba(0, 0, 0, 0)',
                        // zeroLineColor: 'white',
                    },
                    ticks: {
                        display: false,
                    },
                },
            },
            plugins: {
                legend: {
                    position: 'right',
                    align: 'start',
                },

                tooltip: {
                    enabled: true,
                    mode: 'nearest',
                    titleAlign: 'center',
                    bodyFont: { size: 10 },
                    caretSize: 4,
                    titleMarginBottom: 2,
                    titleFont: {
                        size: 12,
                    },
                    // xPadding: 7,
                    // yPadding: 7,
                    position: 'nearest',
                },
                datalabels: {
                    display: false,
                },
            },
        };

        // for (var chartData of this.instancesChartData!.chartData) {
        //   this.barInput.push(chartData.data);
        // }
        this.donutChartData = this.barInput;
    }

    chartClicked(e: any): void {
        if (e.active.length > 0) {
            const chart = e.active[0]._chart;
            const activePoints = chart.getElementAtEvent(e.event);
            if (activePoints.length > 0) {
                // // get the internal index of slice in pie chart
                const clickedElementIndex = activePoints[0]._index;
                const label = chart.data.labels[clickedElementIndex];
                // // get value by index
                const value =
                    this.donutChartData[activePoints[0]._datasetIndex][clickedElementIndex];
                this.chartClickedEvent.emit({
                    visualConfigId: this.visualConfigId,
                    xAxis: label,
                    yAxis: value,
                });
            }
        }
    }
}
