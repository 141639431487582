import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
@Injectable({
    providedIn: 'root',
})
export class ToasterService {
    //private horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    //private verticalPosition: MatSnackBarVerticalPosition = 'top';

    constructor(private messageService: MessageService) {}

    openInfoToaster(summary: string, msg: string, life = 4000): void {
        this.messageService.add({ severity: 'info', summary: summary, detail: msg, life: life });
    }

    openWarningToaster(summary: string, msg: string, life = 4000): void {
        this.messageService.add({ severity: 'warn', summary: summary, detail: msg, life: life });
    }

    openSuccessToaster(summary: string, msg: string, life = 4000): void {
        this.messageService.add({ severity: 'success', summary: summary, detail: msg, life: life });
    }

    openErrorToaster(summary: string, msg: string, life = 4000): void {
        this.messageService.add({ severity: 'error', summary: summary, detail: msg, life: life });
    }
}
