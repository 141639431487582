import { inject } from "@angular/core";
import { LocalStorageProviderService } from "@root/_services/local-storage-provider.service";

import { RxStompConfig } from "@stomp/rx-stomp";

import { environment } from "@root/environments/environment";

export const rxStompConfig: RxStompConfig = {
    brokerURL: environment.wsUrl,

    connectHeaders: {
        
    },

    heartbeatIncoming: 0,
    heartbeatOutgoing: 30000,

    reconnectDelay: 10000,

    debug: (msg: string): void => {
        if (environment.enableConsoleLogging) {
            console.log(new Date(), msg);
        }
    },
};