import { Injectable } from '@angular/core';
import {
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
} from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { CookieService } from '../_services/cookies.service';
import { LocalStorageProviderService } from '../_services/local-storage-provider.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
    constructor(
        private router: Router,
        private cookieService: CookieService,
        private localStorage: LocalStorageProviderService,
        private authenticationService: AuthenticationService
    ) {}

    canActivate() {
        return this.checkAuthentication();
    }

    canActivateChild() {
        return this.checkAuthentication();
    }

    canLoad() {
        return this.checkAuthentication();
    }

    private checkAuthentication(): boolean {
        if (
            this.localStorage.isLocalStorageValid() ||
            this.router.url == '/' ||
            this.router.url === '/auth/login'
        ) {
            return true;
        }

        return false;
    }
}
