/* All free solid fontawesome icons -> https://fontawesome.com/icons?d=gallery&s=solid&m=free */

import {
    faAngleDown,
    faAngleRight,
    faArrowLeft,
    faBars,
    faBookOpen,
    faChartArea,
    faChartBar,
    faChartPie,
    faChevronDown,
    faChevronUp,
    faColumns,
    faSearch,
    faTable,
    faTachometerAlt,
    faUser,
    faUserLock,
    faSignOutAlt,
    faMap,
    faTrash,
    faArrowRight,
    faHome,
    faTimes,
    faSave,
    faCross,
    faPlus,
    faPlusCircle,
    faEdit,
    faKey,
    faUnlockAlt,
    faEnvelope,
    faDesktop,
    faFileAlt,
    faFile,
    faCopy,
    faListUl,
    faClipboardList,
    faFilter,
    faBezierCurve,
    faTasks,
    faProjectDiagram,
    faCheck,
    faTimesCircle,
    faNetworkWired,
    faLayerGroup,
    faEye,
    faScrewdriver,
    faChartLine,
    faComment,
    faCog,
    faRandom,
} from '@fortawesome/free-solid-svg-icons';

export const fontAwesomeSolidIcons = {
    faAngleDown,
    faAngleRight,
    faArrowLeft,
    faBars,
    faBookOpen,
    faChartArea,
    faChartBar,
    faChartPie,
    faChevronDown,
    faChevronUp,
    faColumns,
    faSearch,
    faTable,
    faTachometerAlt,
    faUser,
    faUserLock,
    faSignOutAlt,
    faMap,
    faTrash,
    faArrowRight,
    faHome,
    faTimes,
    faSave,
    faCross,
    faPlus,
    faPlusCircle,
    faEdit,
    faKey,
    faUnlockAlt,
    faEnvelope,
    faDesktop,
    faFileAlt,
    faFile,
    faCopy,
    faListUl,
    faClipboardList,
    faFilter,
    faBezierCurve,
    faTasks,
    faProjectDiagram,
    faCheck,
    faTimesCircle,
    faNetworkWired,
    faLayerGroup,
    faEye,
    faScrewdriver,
    faChartLine,
    faComment,
    faCog,
    faRandom
};
