import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
const routes: Routes = [
    {
        path: 'admin',
        loadChildren: () => import('../app.module').then(m => m.AppModule),
    },
    {
        path: 'auth',
        loadChildren: () =>
            import('../modules/auth/auth-routing.module').then(m => m.AuthRoutingModule),
    },
    { path: '', redirectTo: 'auth', pathMatch: 'full' },
    // { path: '**', redirectTo: '', pathMatch: 'full' }
];
@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: false,
            onSameUrlNavigation: 'reload',
            paramsInheritanceStrategy: 'always',
        }),
    ],
    exports: [RouterModule],
    providers: [],
})
export class RootRoutingModule {}
