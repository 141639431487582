import { User as DatadogUser } from '@datadog/browser-core';
import { datadogRum } from '@datadog/browser-rum';

import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import * as ServiceMappings from '../config/service-mapping';
import { User } from '../_models/user';
import { ApiGatewayService } from './api-gateway.service';
import { PermissionService } from './permission.service';
import {
    CurrentUser,
    LocalStorageData,
    LocalStorageProviderService,
} from './local-storage-provider.service';
import { CookieService } from './cookies.service';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    constructor(
        public http: HttpClient,
        private readonly httpBackend: HttpBackend, // use to bypass the interceptors
        private _ApiGatewayService: ApiGatewayService,
        private lbPermissionService: PermissionService,
        private ngxPermissionsService: NgxPermissionsService,
        private localStorage: LocalStorageProviderService,
        private router: Router,
        private cookieService: CookieService
    ) {}

    public getCurrentUserValue(): User | null {
        if (!this.localStorage.isLocalStorageValid()) {
            return null;
        }

        return this.localStorage.getLocalStorage()!.currentUser;
    }

    public updateRefreshToken(refreshToken: string | null): void {
        const currentUser: User = this.localStorage.getItem('currentUser') || '{}';

        if (refreshToken) {
            currentUser.token = refreshToken;

            // update both the in memory "local storage" and the cookie
            this.localStorage.setItem('currentUser', currentUser);
            this.cookieService.setCookie('token', refreshToken);
        }
    }

    login(username: string, password: string, rememberMe: boolean): Observable<User> {
        const loginUrl: string = ServiceMappings.userLogin;

        return this._ApiGatewayService
            .doPost(
                loginUrl,
                { username, password, rememberMe },
                {
                    isLoaderVisible: true,
                    isSuccessMessageToasterNeeded: true,
                    successMessage: 'User logged in successfully',
                    isErrorMessageToasterNeeded: true,
                    errorMessage: 'Please check username and password',
                }
            )
            .pipe(
                map(user => {
                    if (user) {
                        // map the user data into the DataDog RUM session
                        const datadogUser: DatadogUser = {
                            // these attributes are the structured attributes
                            // expected by DataDog RUM
                            id: user.id,
                            name: user.name,
                            email: user.email,
                            // this object can accept any custom attributes we wish to add
                            // add new ones here, these are pulled from the current
                            // version of LoginResponse in the admin-backend service
                            username: user.username,
                            clientId: user.clientId,
                            clientName: user.clientName,
                            isMfaEnabled: user.isMfaEnabled,
                            roleId: user.roleId,
                        };
                        datadogRum.setUser(datadogUser);

                        const localStorage = new LocalStorageData(
                            user.adminFlag,
                            user.clientId,
                            user.clientName,
                            user.id,
                            user.loggedIn,
                            user.permissions,
                            user.redirectUrl,
                            new CurrentUser(
                                user.id,
                                user.clientId,
                                user.userName,
                                user.password,
                                user.name,
                                user.firstName,
                                user.lastName,
                                user.email,
                                user.isMfaEnabled,
                                user.roleId,
                                user.token,
                                user.redirectUrl
                            )
                        );
                        this.localStorage.setLocalStorage(localStorage);

                        this.cookieService.setCookie('token', user.token);

                        return user;
                    }
                })
            );
    }

    googleAuth(code: number): Observable<any> {
        const url: string = ServiceMappings.TwoFactorAuth;
        return this._ApiGatewayService.doPost(
            url,
            { code },
            {
                isLoaderVisible: true,
                isErrorMessageToasterNeeded: true,
                errorMessage: 'Verification failed',
            }
        );
    }

    getLoggedInUser(token: string) {
        const http = new HttpClient(this.httpBackend);
        const headers = new HttpHeaders()
            .set('Authorization', 'Bearer ' + token)
            .set('Access-Control-Allow-Origin', '*');

        return http.get<any>(`${ServiceMappings.adminDashboardBaseUrl}/loggedinuser`, {
            headers: headers,
        });
    }

    logout() {
        // remove user from local storage to log user out
        this.localStorage.clear();
        this.cookieService.deleteCookie('token');
        this.lbPermissionService.flushPermission();
    }

    verifyUser(refText: string) {
        return this._ApiGatewayService.verifyUser(refText);
    }
}
