import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { LoggingService } from '@root/_services/logging.service';

/**
 * Global error handler for the application.
 * This class provides a cental location for handling errors that are not
 * caught and handled within the application and provides
 * a central location to wire up remote logging at a later date.
 */
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private injector: Injector) {}

    handleError(error: Error) {
        const loggingService = this.injector.get(LoggingService);
        try {
            // Use the injector to access the logging service.
            // This is required because the logger service is created
            // dynamically and not available until after the application starts.
            // The error handler is created at the same time that the application starts.

            loggingService.error('Uncaught Application Exception', error);
        } catch (err) {
            const loggingError = err as Error;
            // This case will only occur if there is a problem with the DI framework.
            loggingService.error(
                'Error occurred while attempting to log error through logging service:',
                loggingError
            );
        }
        throw error;
    }
}
