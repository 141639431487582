import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ConversationComponent } from '@app/modules/shared/components';
import {
    CopilotResponse,
    DeveloperCopilotRequest,
    OutgoingWebSocketCopilotMessage,
    PcsCopilotService,
} from '@app/modules/shared/services/pcs-copilot.service';
import { ChatService } from '../conversation/services/chat.service';
import { LocalStorageProviderService } from '@root/_services/local-storage-provider.service';
import { Message } from '@stomp/stompjs';
import { environment } from '@root/environments/environment';

@Component({
    selector: 'developer-copilot',
    templateUrl: './developer-copilot.component.html',
    styleUrls: ['developer-copilot.component.scss'],
})
export class DeveloperCopilotComponent implements AfterViewInit, OnInit {
    @ViewChild(ConversationComponent) conversation!: ConversationComponent;

    constructor(
        private copilotService: PcsCopilotService,
        private localStorageProviderService: LocalStorageProviderService,
        private cd: ChangeDetectorRef,
        private chatService: ChatService
    ) {}

    ngOnInit(): void {
        // use the copilot service to watch for messages on the copilot response websocket endpoint
        this.copilotService
            // this is a user-specific queue managed by STOMP for messages directed to the currently logged in user
            .watch("/user/queue/copilot-response")
            .subscribe((message: Message) => {
                const body: CopilotResponse = JSON.parse(message.body);
                
                if (environment.enableConsoleLogging) {
                    console.log('Received message:', JSON.stringify(body));
                }

                this.conversation.addResponse(body.message);
            });
    }

    ngAfterViewInit(): void {
        if (this.chatService.getChatHistory().length === 0) {
            this.conversation.addResponse('Welcome to the Lockbox Developer Copilot.');
        }
        this.cd.detectChanges();
    }

    onMessageSent(message: string) {
        // get the relevant user data from local storage
        const userId = this.localStorageProviderService.getItem('currentUser').id;
        const token = this.localStorageProviderService.getItem('currentUser').token;

        // create a request object with the message
        const wsMessage : OutgoingWebSocketCopilotMessage = {
            userId,
            authToken: token,
            message,
            chatHistory: this.conversation.getChatHistory(),
        };

        // publish the message to the copilot chat websocket endpoint
        this.copilotService.publish({
            destination: "/copilot/copilot-chat",
            // the body of the message is a stringified JSON object
            body: JSON.stringify(wsMessage),
        });
    }
}
