import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SectionVO } from '@app/modules/admin/metadata/models/metadata.model';
import { DefineField, DefineFileType } from '@app/modules/admin/network/models/mapping.model';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import * as ServiceMappings from '../../../../config/service-mapping';
import { listOfDefineFields } from '../../../../config/service-mapping';

@Injectable()
export class AppCommonService {
    constructor(private http: HttpClient) { }
    public isSideMenuToogled? = false;

    getAppCommon$(): Observable<any> {
        return of({});
    }

    getDefineFields(): Observable<DefineField[]> {
        return this.http
            .get<any>(ServiceMappings.fieldsListEndpoint, ServiceMappings.requestHeaderDetails)
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    getDefineFieldsByFieldTypeLabel(fieldTypeLabel: any): Observable<DefineField[]> {
        return this.http
            .get<any>(
                ServiceMappings.fieldsListEndpoint + '/' + fieldTypeLabel,
                ServiceMappings.requestHeaderDetails
            )
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    getSnowflakeDefineFields(): Observable<DefineField[]> {
        return this.http
            .get<any>(
                ServiceMappings.snowflakeFieldsListEndpoint,
                ServiceMappings.requestHeaderDetails
            )
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    getListOfDefineFields(): Observable<DefineField[]> {
        return this.http
            .get<any>(
                ServiceMappings.listOfDefineFields,
                ServiceMappings.requestHeaderDetails
            )
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    getAllDefineFields(): Observable<DefineField[]> {
        return this.http
            .get<any>(ServiceMappings.allFieldListEndpoint, ServiceMappings.requestHeaderDetails)
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    getBucketStagingFields(fileId: any): Observable<DefineField[]> {
        return this.http
            .get<any>(
                ServiceMappings.bucketFileEndpoint + '/' + fileId,
                ServiceMappings.requestHeaderDetails
            )
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    getFileTypes(): Observable<DefineFileType[]> {
        return this.http
            .get<any>(ServiceMappings.searchFileEndpoint, ServiceMappings.requestHeaderDetails)
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    getSections(): Observable<SectionVO[]> {
        return this.http
            .get<any>(
                ServiceMappings.allActiveSectionListEndpoint,
                ServiceMappings.requestHeaderDetails
            )
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }
    getDefineFieldsByFileType(fileTypeId: string): Observable<DefineField[]> {
        const url = ServiceMappings.defineFieldByFileTypeListEndpoint.replace(
            '{fileTypeId}',
            fileTypeId
        );
        return this.http.get<any>(url, ServiceMappings.requestHeaderDetails).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    listOfFieldsByFileIds(id: any) {
        return this.http
            .post<any>(
                ServiceMappings.listOfFieldsByFileIds,
                id,
                ServiceMappings.requestHeaderDetails
            )
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    listOfFieldsByFileIdsV2(id: any) {
        return this.http
            .get<any>(
                ServiceMappings.adminBaseUrlV2 + '/fieldsByFileId/' + id,
                ServiceMappings.requestHeaderDetails
            )
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    filterNumberOrCurrencyFields(definedFieldsList?: DefineField[]) {
        const defineFieldNumberOrCurrencyList = definedFieldsList?.filter((field: any) => {
            return field.fieldTypeLabel === 'Number' || field.fieldTypeLabel === 'Currency';
        });
        return defineFieldNumberOrCurrencyList;
    }

    filterTextFields(definedFieldsList?: DefineField[]) {
        const defineFieldsTextList = definedFieldsList?.filter((field: any) => {
            return field.fieldType?.type === 'Text';
        });
        return defineFieldsTextList;
    }

    filterDateFields(definedFieldsList?: DefineField[]) {
        const defineFieldsTextList = definedFieldsList?.filter((field: any) => {
            return field.fieldTypeLabel === 'Date';
        });
        return defineFieldsTextList;
    }
    filterMultiselectData(object: any, dataList: any, id: any) {
        const returnData: any = [];
        object?.forEach((value: any) => {
            if (
                dataList.findIndex((data: any) => data[id].toString() === value.toString()) !== -1
            ) {
                returnData.push(value);
            }
        });
        return returnData;
    }
    filterMultiselectDataObject(object: any, dataList: any, id: any) {
        const returnData: any = [];
        object?.forEach((value: any) => {
            dataList.forEach((data: any) => {
                if (data[id] === value[id]) {
                    returnData.push(data);
                }
            });
        });
        return returnData;
    }
    findSectionIdByFieldId(fieldList?: any[], fieldId?: string) {
        return fieldList?.find(x => x.id === fieldId)?.sectionIds;
    }
    filterFieldByDestinationFieldId(defineFieldsList: any, destinationFieldID: any) {
        const destinationField = defineFieldsList?.filter((field: any) => {
            return field.id === destinationFieldID;
        });
        return defineFieldsList?.filter((field: any) => {
            return (
                destinationField &&
                destinationField.length > 0 &&
                this.isFieldSectionIdExistsInSectionIds(destinationField[0].sectionIds, field)
            );
        });
    }

    filterFieldBySectionId(defineFieldsList: any, sectionIds: any) {
        return defineFieldsList?.filter((field: any) => {
            return this.isFieldSectionIdExistsInSectionIds(sectionIds, field);
        });
    }

    filterDataConvert(value: any, dataList: any, id: any) {
        if (!value) {
            return value;
        }
        return dataList.findIndex((data: any) => data[id] === value) === -1 ? null : value;
    }

    filterData(value: any, dataList: any, id: any) {
        if (!value) {
            return value;
        }
        return dataList.findIndex((data: any) => data[id].toString() === value.toString()) === -1
            ? null
            : value;
    }

    isFieldSectionIdExistsInSectionIds(sectionIds: any, field: any): boolean {
        let isSectionPresent = false;
        field?.sectionIds?.forEach((fieldSectionId: any) => {
            isSectionPresent = sectionIds.indexOf(fieldSectionId) !== -1;
        });
        return isSectionPresent;
    }

    getFileDetails(id?: string): Observable<any> {
        return this.http
            .get<any>(
                ServiceMappings.fileCommonEndpoint + '/' + id,
                ServiceMappings.requestHeaderDetails
            )
            .pipe(
                map((response: any) => {
                    return response.responseObject;
                })
            );
    }
}
