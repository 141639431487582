<ng-template #deleteAlert let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Alert</h4>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">

                <div class="form-group text-center align-items-center marT20">
                    <span class="col-form-label">Are you sure want to delete?</span>
                </div>

            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" (click)="deleteItem()" ngbAutofocus class="btn btn-success">
            Yes
        </button>
        <button type="button" class="btn btn-secondary" aria-label="Close" (click)="deleteModal.close();">
            No
        </button>
    </div>
</ng-template>