import { Injectable } from '@angular/core';
import { environment } from '@root/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class CookieService {
    public getCookie(name: string) {
        const ca: Array<string> = document.cookie.split(';');
        const caLen: number = ca.length;
        const cookieName = `${name}=`;
        let c: string;

        for (let i = 0; i < caLen; i += 1) {
            c = ca[i].replace(/^\s+/g, '');
            if (c.indexOf(cookieName) == 0) {
                const cookieValue = c.substring(cookieName.length, c.length);

                // if the cookie is found and it is not empty, return the value
                if (
                    cookieValue &&
                    cookieValue !== 'null' &&
                    cookieValue !== 'undefined' &&
                    cookieValue !== ''
                ) {
                    return cookieValue;
                }
            }
        }

        // if the cookie is not found, return undefined
        return undefined;
    }

    public deleteCookie(name: string) {
        this.setCookie(name, '', -1);
    }

    public setCookie(name: string, value: any, deleteFlag?: any) {
        const expires = `expires=-1`;
        const path = `; path=/`;
        
        let domain = `; domain=.lockboxai.com`;
        if (environment.baseUrl.includes('localhost') || window.location.hostname.includes('localhost')) {
            domain = '; domain=localhost';
        }

        // set the cookie securely if the base URL is HTTPS and the browser is running on HTTPS
        let secure = '';
        if (environment.baseUrl.startsWith('https') && window.location.protocol === 'https:') {
            secure += '; Secure';
        }

        if (deleteFlag) {
            // delete cookie
            document.cookie = `${name}=${value}; ${expires}${path}${domain}${secure}`;
        } else {
            if (value && value !== 'null' && value !== 'undefined' && value !== '') {
                // add cookie
                document.cookie = `${name}=${value}${path}${domain}${secure}`;
            }
        }
    }
}
