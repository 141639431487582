import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LoaderService {
    private isLoaderVisible = false;

    public reset(): void {
        this.isLoaderVisible = false;
    }

    public showLoader(): void {
        this.isLoaderVisible = true;
    }

    public getIsLoaderVisible(): boolean {
        return this.isLoaderVisible;
    }
}
