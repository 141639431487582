import { APP_INITIALIZER, NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { interval } from 'rxjs';

import { RootRoutingModule } from './root-routing.module';
import { JwtInterceptor, ErrorInterceptor } from './../../_helpers';
import { RootComponent } from './root/root.component';
import { SharedComponentModule } from '../modules/shared/shared-common.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ToastModule } from 'primeng/toast';
import { MessagesModule } from 'primeng/messages';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessageService } from 'primeng/api';
import { ClientRootComponent } from './client-root/client-root.component';

import { environment } from '@root/environments/environment';

import { LoggingService } from '@root/_services/logging.service';
import { DataService } from '@app/data.service';
import {
    LOCAL_STORAGE_APPLICATION_VERSION_KEY,
    VersionCheckService,
} from '@root/_services/version-check.service';
import { ConfirmationService as NgPrimeConfirmationService } from 'primeng/api';
import { ConfirmationService } from '@root/_services/confirmation.service';

import { RouteReuseStrategy } from '@angular/router';
import { CustomReuseStrategy } from '@app/reuse-strategy';
import { GlobalErrorHandler } from '@root/_helpers/error.handler';

// import { TimingInterceptor } from '@root/_helpers/timing.interceptor';

/**
 * Initializes the application by logging the current application version.
 * @param loggingService The logging service.
 * @returns A function that logs the application version. Important: This function must return a function for the APP_INITIALIZER to work.
 */
function initLogApplicationVersion(loggingService: LoggingService) {
    return () => {
        const currentVersion =
            localStorage.getItem(LOCAL_STORAGE_APPLICATION_VERSION_KEY) ||
            environment.applicationVersion;
        loggingService.info(`Running application version: ${currentVersion}`);
    };
}

/**
 * Initializes the version check service. This service will check for a new version
 * as deployed on the server and notify the user if a new version is available
 * on a regular interval.
 *
 * @param loggingService The logging service.
 * @param versionCheckService The version check service.
 * @returns A function that checks for a new version. Important: This function must return a function for the APP_INITIALIZER to work.
 */
function initVersionCheck(
    loggingService: LoggingService,
    versionCheckService: VersionCheckService
) {
    return () => {
        // Set the check interval.
        // The check interval is specified in seconds in the environment file.
        // Convert the interval to milliseconds.
        const checkInterval = environment.versionCheckInterval * 1000;

        // This flag tells us the version check is running.
        let versionCheckRunning = false;

        // check for the version in local storage
        const currentRunningVersionFromLocalStorage = localStorage.getItem(
            LOCAL_STORAGE_APPLICATION_VERSION_KEY
        );

        // If the version is not found in local storage, set it.
        if (!currentRunningVersionFromLocalStorage) {
            localStorage.setItem(
                LOCAL_STORAGE_APPLICATION_VERSION_KEY,
                environment.applicationVersion
            );
        } else {
            // deal with the case when the version is found in local storage
            // but it is both stale from a previous deployment and the value in the environment file
            // is has a higher version number

            // get the version number from from the environment file
            // version numbers are int the format "<env_string>-<version_number>"
            // where <env_string> is the environment name and <version_number> is the version number
            // "version_number" is an integer
            const currentRunningVersionFromEnvironment = environment.applicationVersion;
            const currentRunningVersionFromEnvironmentSplit =
                currentRunningVersionFromEnvironment.split('-');
            const currentRunningVersionFromEnvironmentVersion =
                currentRunningVersionFromEnvironmentSplit[1];

            // get the version number from local storage
            const currentRunningVersionFromLocalStorageSplit =
                currentRunningVersionFromLocalStorage.split('-');
            const currentRunningVersionFromLocalStorageVersion =
                currentRunningVersionFromLocalStorageSplit[1];

            // compare the version numbers
            if (
                currentRunningVersionFromEnvironmentVersion >
                currentRunningVersionFromLocalStorageVersion
            ) {
                // if the version number from the environment file is greater than the version number from local storage
                // update the version number in local storage
                localStorage.setItem(
                    LOCAL_STORAGE_APPLICATION_VERSION_KEY,
                    environment.applicationVersion
                );
            }
        }

        // Periodic check for updates
        interval(checkInterval).subscribe(() => {
            // If the version check is already running, exit.
            if (versionCheckRunning) {
                return;
            } else {
                versionCheckRunning = true;
            }

            loggingService.debug('Checking for new version...');
            checkForNewVersion();

            // Reset the version check flag.
            versionCheckRunning = false;
        });

        /**
         * Checks for a new version of the application by comparing the version
         * stored in local storage with the version deployed on the server.
         */
        function checkForNewVersion() {
            // Fetch new version using fetch API
            fetch('assets/version')
                .then(response => {
                    if (!response.ok) {
                        throw new Error(
                            `HTTP error checking version file. Status: ${response.status}`
                        );
                    }
                    return response.text();
                })
                .then(newVersion => {
                    // Use the version from the environment file as the old version
                    const oldVersion = localStorage.getItem(LOCAL_STORAGE_APPLICATION_VERSION_KEY);
                    if (!oldVersion) {
                        loggingService.warn(
                            'No application version found in local storage. Skipping version check.'
                        );
                        return;
                    }

                    // Check if the version has changed
                    if (oldVersion !== newVersion) {
                        // perform a comparison of the version numbers
                        const oldVersionSplit = oldVersion.split('-');
                        const newVersionSplit = newVersion.split('-');
                        const oldVersionNumber = oldVersionSplit[1];
                        const newVersionNumber = newVersionSplit[1];

                        // if the version number from the environment file is greater than the version number from local storage
                        // prompt the user to reload the page
                        if (newVersionNumber > oldVersionNumber) {
                            loggingService.info(
                                `A new version of the application is available on the server: '${newVersion}'.`
                            );

                            // notify the user for a page refresh to load a new application version
                            versionCheckService.showReloadAlert(oldVersion, newVersion);
                        }
                    } else {
                        loggingService.debug('Application and server versions are in sync.');
                    }
                })
                .catch(error => {
                    loggingService.error('Error reading version file:', error);
                });
        }
    };
}

/**
 * This module is the root module for the application.
 * It is used in the primary bootstrap process to begin the application.
 */
@NgModule({ declarations: [RootComponent, ClientRootComponent],
    exports: [SharedComponentModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [RootComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        RootRoutingModule,
        SharedComponentModule,
        ToastModule,
        ConfirmDialogModule,
        MessagesModule,
        NgxPermissionsModule.forRoot()], providers: [
        NgPrimeConfirmationService,
        ConfirmationService,
        LoggingService,
        VersionCheckService,
        {
            provide: APP_INITIALIZER,
            useFactory: initLogApplicationVersion,
            deps: [LoggingService],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initVersionCheck,
            deps: [LoggingService, VersionCheckService],
            multi: true,
        },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        // { provide: HTTP_INTERCEPTORS, useClass: TimingInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        MessageService,
        DataService,
        { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class RootModule {}
