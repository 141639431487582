import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../_services/authentication.service';
import { tap } from 'rxjs/operators';
import { CookieService } from '../_services/cookies.service';
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
        private cookieService: CookieService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let headers: HttpHeaders = new HttpHeaders();

        const currentUser = this.authenticationService.getCurrentUserValue();

        let token;
        if (!currentUser) {
            token = this.cookieService.getCookie('token');

            if (!token || token === 'null' || token === 'undefined' || token === '') {
                token = undefined;
            }
        } else {
            token = currentUser.token;
        }

        if (token) {
            headers = headers.append('Authorization', `Bearer ${token}`);
            headers = headers.append('e2e-trace-id', this.generateUniqueHeaderValue());
        }

        request = request.clone({ headers });

        return next.handle(request).pipe(
            tap(response => {
                if (response instanceof HttpResponse) {
                    if (response.headers.has('RefreshToken')) {
                        this.authenticationService.updateRefreshToken(
                            response.headers.get('RefreshToken')
                        );
                    }
                }
            })
        );
    }

    private generateUniqueHeaderValue(): string {
        const uuid = uuidv4().replace(/-/g, '');
        const id =
            uuid.substring(0, 8) +
            uuid.substring(9, 13) +
            uuid.substring(14, 18) +
            uuid.substring(19, 23) +
            uuid.substring(24);
        return id;
    }
}
